html {
    scroll-behavior: smooth;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: #00000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: Arial;
    font-size: 16px;
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*background-color: #222;*/
    background-color: #222;
    opacity: 1;
    background-size: 8px 8px;
    background-image: repeating-linear-gradient(45deg, #2d2d2d 0, #2d2d2d 0.8px, #222 0, #222 50%);
    color: #acbac4;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
    position: relative;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
    color: #3888ff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #627fc6;
    margin-top: 0;
}
