a:hover {
    text-decoration: none;
}
.centered {
    padding: 0 20px;
}

.example-header {
    background: #3d4351;
    color: #fff;
    font-weight: 300;
    padding: 3em 1em;
    text-align: center;
    margin: 0 auto;
}
.example-header h1 {
    color: #fff;
    font-weight: 300;
    margin-bottom: 20px;
}
.example-header p {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
}
.container-fluid .row {
    padding: 0 0 4em 0;
}
.container-fluid .row:nth-child(even) {
    background: #f1f4f5;
}
.title {
    text-align: center;
    margin: 60px auto -10px auto;
}
.title p {
    margin: 0 auto;
    font-size: 16px;
    max-width: 400px;
}

.branch {
    line-height: 1.4em;
    list-style: none;
    margin: 0;
    padding: 30px;
    width: 100%;
}
.branch.branch-centered {
    padding: 30px 10px;
}

.branch h1,
.branch h2,
.branch h3,
.branch h4,
.branch h5,
.branch h6 {
    line-height: inherit;
}

.item {
    padding-left: 40px;
    position: relative;
}
.item:last-child {
    padding-bottom: 0;
}
.date-title {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 3px;
    margin: 0 0 0.5em 0;
    text-transform: uppercase;
    white-space: nowrap;
    padding-top: 10px;
}
.node-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 15px;
}

.node-icon:after {
    content: "";
    width: 3px;
    background: #ccd5db;
    display: block;
    position: absolute;
    top: 44px;
    bottom: 0;
    left: 6px;
}
.item:last-child .node-icon:after {
    content: none;
}
.item:not(.period):hover .node-icon:before {
    background: transparent;
    border: 3px solid #ff6b6b;
}
.content {
    padding-bottom: 0px;
    margin-top: 20px;
}
.content a {
}
.content h3 {
    font-size: 20px;
    margin-bottom: 10px;
}
.content a:hover {
    /*text-decoration: underline;*/
    color: #9cc4ff;
}
.content p:last-child {
    margin-bottom: 0;
}
.period {
    padding: 0;
}
.period .date-title {
    display: none;
}
.period .node-icon:before {
    background: transparent;
    content: "";
    width: 15px;
    height: auto;
    border: none;
    border-radius: 0;
    top: 0;
    bottom: 30px;
    position: absolute;
    border-top: 3px solid #ccd5db;
    border-bottom: 3px solid #ccd5db;
}
.period .node-icon:after {
    content: "";
    height: 32px;
    top: auto;
}
.period .content {
    padding: 40px 0 70px;
}
.period .item-title {
    margin: 0;
}
@media (min-width: 768px) {
    .branch.branch-centered {
        padding: 00px 10px;
    }

    .title {
        text-align: center;
        margin: 30px auto;
    }

    .branch-split .branch,
    .branch-centered .branch {
        display: table;
    }
    .branch-split .item,
    .branch-centered .item {
        display: table-row;
        padding: 0;
    }
    .branch-split .date-title,
    .branch-centered .date-title,
    .branch-split .node-icon,
    .branch-centered .node-icon,
    .branch-split .content,
    .branch-centered .content,
    .branch-split .period .date-title,
    .branch-centered .period .date-title {
        display: table-cell;
        vertical-align: top;
    }
    .branch-split .node-icon,
    .branch-centered .node-icon {
        position: relative;
    }
    .branch-split .content,
    .branch-centered .content {
        padding-left: 30px;
    }
    .branch-split .date-title,
    .branch-centered .date-title {
        padding-right: 30px;
    }
    .branch-split .period .item-title,
    .branch-centered .period .item-title {
        position: relative;
        left: -45px;
    }
}

@media (min-width: 992px) {
    .branch-centered,
    .branch-centered .item,
    .branch-centered .date-title,
    .branch-centered .node-icon,
    .branch-centered .content {
        display: block;
        margin: 0;
        padding: 0;
    }
    .branch-centered .item {
        padding-bottom: 40px;
        overflow: hidden;
    }
    .branch-centered .node-icon {
        position: absolute;
        left: 50%;
        margin-left: -7.5px;
    }
    .branch-centered .date-title,
    .branch-centered .content {
        width: 50%;
    }
    .branch-centered > .item:nth-child(odd) .date-title {
        float: left;
        text-align: right;
        padding-right: 30px;
        margin-top: 10px;
    }
    .branch-centered > .item:nth-child(odd) .content {
        float: right;
        text-align: left;
        padding-left: 40px;
        margin-top: 10px;
    }
    .branch-centered > .item:nth-child(odd) .content .item-title {
        border-radius: 5px;
        padding: 5px;
        background: rgb(84,84,84);
        background: linear-gradient(270deg, rgba(84,84,84,0.1) 0%, rgba(58, 58, 58, 0.583) 100%);
        }
    .branch-centered > .item:nth-child(even) .date-title {
        float: right;
        text-align: left;
        padding-left: 40px;
        margin-top: 10px;
    }
    .branch-centered > .item:nth-child(even) .content {
        float: left;
        text-align: left;
        padding-right: 40px;
        margin-top: 10px;
        /*color: red;*/
    }
    .branch-centered > .item:nth-child(even) .content .item-title {
        border-radius: 5px;
        padding: 5px;
        background: rgb(84,84,84);
        background: linear-gradient(90deg, rgba(84,84,84,0.1) 0%, rgba(58, 58, 58, 0.583) 100%);
        }
    
    .branch-centered > .item.period .content {
        float: none;
        padding: 0;
        width: 100%;
        text-align: center;
    }
    .branch-centered .item.period {
        padding: 50px 0 90px;
    }
    .branch-centered .period .node-icon:after {
        height: 30px;
        bottom: 0;
        top: auto;
    }
    .branch-centered .period .item-title {
        left: auto;
    }
}

.marker-outline .node-icon:before {
    background: transparent;
    border-color: #ff6b6b;
}
.marker-outline .item:hover .node-icon:before {
    background: #ff6b6b;
}
